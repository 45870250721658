import axios from "axios";

export function createEvent(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FORMS_API}/api/events`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("errior", error.response);
        reject(error);
      });
  });
}

export function createSubEvent(data, event_url, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FORMS_API}/api/sub_events/${event_url}`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("errior", error.response);
        reject(error);
      });
  });
}

export const getAllEvents = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/events`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }

      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  })
}

export const getEventsByCreator = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/events/eventsByCreator`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }

      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  })
}

export function updateEvent(id, data, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function updateSubEvent(id, data, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/sub_events/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function updateSharedPassword(id, data, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/update-shared-password/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function updateEventAccess(id, data, newEventAccessArray) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/updateEventAccess/${id}/${newEventAccessArray}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function deleteEvent(id, token) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_FORMS_API}/api/events/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function login(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FORMS_API}/api/events/login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function checkURLAvailability(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/events/availabilityCheck?event_url=${data}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function getByEventUrl(event_url) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/events/${event_url}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function uploadImage(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FORMS_API}/api/upload/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/events/getbyid/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function getEventNameById(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/events/getEventNameById/${id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function streamFormat(data, apiKey) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_RTMP_API}/streamFormat`, data, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey
        },
      })
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function encodingTemplate(data, apiKey) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_RTMP_API}/encodingTemplate`, data, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey
        },
      })
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getAllEncodingTemplate(apiKey) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_RTMP_API}/encodingTemplate`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey
        },
      })
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function createRtmp(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_RTMP_API}/rtmp`, data, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_RTMP_API_KEY
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function deleteRtmp(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${process.env.REACT_APP_RTMP_API}/rtmp/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.REACT_APP_RTMP_API_KEY
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function rtmpGetAll(headerPayload) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_RTMP_API}/rtmp`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": headerPayload['x-api-key'],
          "tags": headerPayload['tags']
          // tags: { 'key': 'eventID', 'value': headerPayload.tags.value }
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function webhook(apiKey) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_RTMP_API}/webhook`, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function createWebhook(data, apiKey) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_RTMP_API}/webhook`, data, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey
        },
      })
      .then((response) => {

        if (response.status === 201) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function updateFeaturesStatus(id, event_url, data, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/updateFeaturesStatus/${id}/${event_url}`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function updateQnASettings(id, event_url, data, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/updateQnASettings/${id}/${event_url}`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Berar ${token}`
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function reorderQuestionAndAnswersService(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/reorderQuestionAndAnswers/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function getUser(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/${id}`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getAllUser(user_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/getAll/${user_id}`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getUsersOfEvent(event_id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/byEventAccess/${event_id}`, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function updateUser(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_LIVESTREAM_API}/api/users/${id}`, data, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getIndexPageContent(event_url) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_FORMS_API}/api/events/loadTemplate/${event_url}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function uploadSource(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/update-source/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function updateEmbedAlt(id, event_url, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/update-embedAlt/${id}/${event_url}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function updateProjectorPageSetting(id, event_url, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/update-projector-page-setting/${id}/${event_url}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function updateSourceScheduledStream(data, eventId) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/update-source-scheduled-stream/${eventId}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}

export function singleSharedPasswordlogin(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FORMS_API}/api/events/singleSharedPasswordlogin`, data, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("errior", error.response);
        reject(error);
      });
  });
}

export function uniquelogin(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_FORMS_API}/api/attendees/login`, data, {
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("errior", error.response);
        reject(error);
      });
  });
}

export function updateSourceChapters(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${process.env.REACT_APP_FORMS_API}/api/events/update-source-chapters/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error.response);
        reject(error);
      });
  });
}