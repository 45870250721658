import * as types from "../../constants";

const initialState = {
  user: undefined,
  roles: [],
  errorMsg: '',
  successMsg: '',
  emails: [],
  collaborators: []
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS: {
      localStorage.setItem("token", actions.token);
      localStorage.setItem("user_details", JSON.stringify(actions.user_details));
      return {
        ...state,
        user: {
          token: actions.token,
          user_details: actions.user_details
        },
      };
    }

    case types.AUTH_SIGN_OUT:
      localStorage.removeItem("token");
      localStorage.removeItem("user_details");
      return {
        ...state,
        user: undefined,
      };

    case types.AUTH_SIGN_UP_SUCCESS:
      return {
        ...state,
        successMsg: `You've successfully signed up! \n We've sent an email. Open it up to activate your account`,
      };
    
     case types.AUTH_UPDATE_SIGN_UP_SUCCESS:
      return {
        ...state,
        successMsg: `You've successfully signed up!`,
      };
    
    case types.GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        roles: actions.roles,
      };
    case types.GET_ALL_EMAILS_SUCCESS:
      return {
        ...state,
        emails: actions.emails,
      };
     case types.GET_COLLABORATOR_SUCCESS:
      return {
        ...state,
        collaborators: actions.collaborators,
      };
    case types.VERIFY_EMAIL_SUCCESS: {
      localStorage.setItem("token", actions.token);
      localStorage.setItem("user_details", JSON.stringify(actions.user_details));
      return {
        ...state,
        user: {
          token: actions.token,
          user_details: actions.user_details
        },
      };
    }

    default: {
      const token = localStorage.getItem("token")
      const user_details = JSON.parse(localStorage.getItem("user_details"));
      if (token) {
        return {
          ...state,
          user: {
            token,
            user_details,
          }
        };
      } else {
        return state
      }
    }
  }
}
